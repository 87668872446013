import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { AppContext, hasActivePlan } from '../context/global';
import Modal from '../common/containers/Modal';

class FreshDesk extends Component {
  constructor(props) {
    super(props);

    this.state = { showModal: false };

    this.openWidget = this.openWidget.bind(this);
    this.loadScript = this.loadScript.bind(this);
    this.openWidgetCancelamento = this.openWidgetCancelamento.bind(this);
    this.hideAndFillProperties = this.hideAndFillProperties.bind(this);
    this.getPriority = this.getPriority.bind(this);
    this.getDescription = this.getDescription.bind(this);
  }

  componentDidMount() {
    this.hideAndFillProperties();
  }

  getDescription() {
    let description = '\n\n\n-------\nNão apague as informações abaixo\n';

    if (this.props.documentId) {
      description += `[document_id: ${this.props.documentId}]\n`;
    }

    if (this.context.current_plan_name) {
      description += `[plan_name: ${this.context.current_plan_name}]\n`;
    }

    return description;
  }

  getPriority() {
    const { user } = this.context;
    const hoursSinceJoined = (Date.now() - new Date(user.date_joined).getTime()) / 1000 / 60 / 60;
    let priority = 1;

    if (hasActivePlan()) {
      priority = 4;
    } else if (hoursSinceJoined <= 10) {
      priority = 3;
    } else if (hoursSinceJoined <= 20) {
      priority = 2;
    }

    return priority;
  }

  openWidget() {
    if (typeof FreshworksWidget === 'undefined') {
      this.loadScript(this.openWidget);
    } else {
      FreshworksWidget('clear', 'ticketForm');
      FreshworksWidget('prefill', 'ticketForm', {
        description: this.getDescription(),
        priority: this.getPriority(),
        custom_fields: {
          document_id: this.props.documentId,
          plan_name: this.context.current_plan_name,
          paid: hasActivePlan(),
        },
      });
      FreshworksWidget('disable', 'ticketForm', ['email']);
      FreshworksWidget('open');
    }
  }

  openWidgetCancelamento() {
    if (typeof FreshworksWidget === 'undefined') {
      this.loadScript(this.openWidgetCancelamento);
    } else {
      FreshworksWidget('prefill', 'ticketForm', {
        subject: `Cancelamento (Plano ${this.context.current_plan_name})`,
        description: this.getDescription(),
        priority: this.getPriority(),
        custom_fields: {
          document_id: this.props.documentId,
          plan_name: this.context.current_plan_name,
          paid: hasActivePlan(),
        },
      });
      FreshworksWidget('disable', 'ticketForm', ['email']);
      FreshworksWidget('open', 'ticketForm');
    }
  }

  loadScript(callback) {
    this.setState((state) => ({ ...state, showModal: true }));

    window.fwSettings = {
      widget_id: 16000000214,
    };
    !(function () {
      if ('function' != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();

    const src = 'https://widget.freshworks.com/widgets/16000000214.js';
    if (!document.querySelector(`[src="${src}"]`)) {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = src;
      script.onload = () => {
        this.hideAndFillProperties();
        if (callback) {
          callback();
        }
        setTimeout(() => this.setState((state) => ({ ...state, showModal: false })), 1000);
      };
      document.body.appendChild(script);
    }
  }

  hideAndFillProperties() {
    if (typeof FreshworksWidget === 'undefined') {
      return;
    }

    FreshworksWidget('hide', 'launcher');
    const { user } = this.context;

    FreshworksWidget('identify', 'ticketForm', {
      name: !!user && user.fullname ? user.fullname : '',
      email: !!user ? user.email : '',
    });

    FreshworksWidget('hide', 'ticketForm', ['priority', 'custom_fields.document_id', 'custom_fields.plan_name']);
  }

  render() {
    const { i18n, showIcon } = this.props;
    return (
      <>
        <a role="button" onClick={this.openWidget}>
          {showIcon && <i className="mdi mdi-help" />} {this.props.label || i18n.t('Contato/Suporte')}
        </a>

        {hasActivePlan() && (
          <a role="button" onClick={this.openWidgetCancelamento}>
            <span className="btn btn-danger btn-sm">{i18n.t('Cancelar assinatura')}</span>
          </a>
        )}

        {this.state.showModal && <Modal title="Carregando suporte..." isProcessing={true} show={true} />}
      </>
    );
  }
}

FreshDesk.propTypes = {
  documentId: PropTypes.number,
  showIcon: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

FreshDesk.defaultProps = {
  showIcon: true,
};

FreshDesk.contextType = AppContext;

export default withNamespaces()(FreshDesk);
